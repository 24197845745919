<template>
  <div class="promo-code">
    <!-- delete dialog box -->
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Confirmation
        </v-card-title>

        <v-card-text>
          <br />
          Are you sure you want to delete this promo code:
          <b>{{ delPromo.name }}</b
          >?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="doDelPromo()"> Yes </v-btn>
          <v-btn color="primary" text @click="deleteDialog = false"> No </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- add Promo dialog box -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Add Promo
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="addPromo.name" label="Name"></v-text-field>
          <v-text-field
            v-model="addPromo.promoCode"
            label="Promo Code"
          ></v-text-field>
          <v-text-field
            v-model="addPromo.emailTemplate"
            label="Email Template"
          ></v-text-field>
          <v-text-field
            v-model="addPromo.planDuration"
            label="Plan Duration"
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <div style="margin-top: 16px">Start Date:</div>
          <v-datetime-picker v-model="addPromo.startDate">
            <template slot="dateIcon">
              <v-icon>Date</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>Time</v-icon>
            </template>
          </v-datetime-picker>

          <div style="margin-top: 16px">End Date:</div>
          <v-datetime-picker v-model="addPromo.endDate">
            <template slot="dateIcon">
              <v-icon>Date</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>Time</v-icon>
            </template>
          </v-datetime-picker>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doAddPromo()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit dialog box -->
    <v-dialog v-model="editDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit Promo
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="editPromo.name" label="Name"></v-text-field>
          <v-text-field
            v-model="editPromo.promoCode"
            label="Promo Code"
          ></v-text-field>
          <v-text-field
            v-model="editPromo.emailTemplate"
            label="Email Template"
          ></v-text-field>
          <v-text-field
            v-model="editPromo.planDuration"
            label="Plan Duration"
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-card-text>
            <div style="margin-top: 16px">Start Date:</div>
            <v-datetime-picker v-model="editPromo.startDate">
              <template slot="dateIcon">
                <v-icon>Date</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>Time</v-icon>
              </template>
            </v-datetime-picker>

            <div style="margin-top: 16px">End Date:</div>
            <v-datetime-picker v-model="editPromo.endDate">
              <template slot="dateIcon">
                <v-icon>Date</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>Time</v-icon>
              </template>
            </v-datetime-picker>
          </v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditPromo()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- floating button [for add] -->
    <v-btn
      fab
      absolute
      class="plusButton"
      right
      bottom
      color="red accent-2"
      @click="dialog = !dialog"
    >
      <v-icon color="#fff">mdi-plus</v-icon>
    </v-btn>

    <v-data-table
      :headers="headers"
      :items="PromoCodeList"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="row">
        <tr v-if="row.item.id != null">
          <td>{{ row.item.name }}</td>
          <td>{{ row.item.promoCode }}</td>
          <td>{{ row.item.emailTemplate }}</td>
          <td>{{ row.item.planDuration }}</td>
          <td>{{ convertDate(row.item.startDate) }}</td>
          <td>{{ convertDate(row.item.endDate) }}</td>
          <!-- tbl row edit btn -->
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="primary"
              @click="editButtonClick(row.item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
          <!-- tbl row del btn -->
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="red"
              @click="delButtonClick(row.item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10000"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import moment from "moment";
import DatetimePicker from "vuetify-datetime-picker";
import JsonExcel from "vue-json-excel";

import getAxios from "../../../services/axios-get";
import putAxios from "../../../services/axios-put";
import postAxios from "../../../services/axios-post";
import deleteAxios from "../../../services/axios-delete";

Vue.use(DatetimePicker);
Vue.use(VueAxios, axios, moment);
Vue.component("downloadExcel", JsonExcel);

export default {
  data() {
    return {
      PromoCodeList: [],
      pageCount: 0,
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      headers: [
        // { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Promo Code", value: "promoCode" },
        { text: "Email Template", value: "emailTemplate" },
        { text: "Plan Duration", value: "planDuration" },
        { text: "Start Date", value: "startDate" },
        { text: "End Date", value: "endDate" },
        { text: "", value: "" },
      ],
      addPromo: {
        name: null,
        promoCode: null,
        emailTemplate: null,
        planDuration: null,
        startDate: null,
        endDate: null,
      },
      editPromo: {
        id: "",
        name: null,
        promoCode: null,
        emailTemplate: null,
        planDuration: null,
        startDate: null,
        endDate: null,
      },
      delPromo: {
        id: "",
        name: "",
      },
      dialog: false,
      editDialog: false,
      deleteDialog: false,
    };
  },
  computed: {},
  mounted() {
    this.fetchCredentials();
    this.fetchPromoCodeList();
  },
  methods: {
    convertDate(date) {
      var parsedDate = moment
        .utc(date)
        .local()
        .format("dddd, MMMM Do YYYY, h:mm:ss A");

      return parsedDate;
    },
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchPromoCodeList();
    },
    delButtonClick(item) {
      this.delPromo.id = item.id;
      this.delPromo.name = item.name;
      this.deleteDialog = true;
    },
    editButtonClick(item) {
      this.editPromo.id = item.id;
      this.editPromo.name = item.name;
      this.editPromo.promoCode = item.promoCode;
      this.editPromo.emailTemplate = item.emailTemplate;
      this.editPromo.startDate = new Date(item.startDate);
      this.editPromo.endDate = new Date(item.endDate);
      this.editPromo.planDuration = item.planDuration;
      this.editDialog = true;
    },
    doDelPromo() {
      const self = this;
      const param = {
        promoId: self.delPromo.id,
      };
      deleteAxios(
        `${process.env.VUE_APP_SERVER_URL}/magazineSystem/plan/${self.delPromo.id}`,
        param,
        self.token
      )
        .then(res => {
          if (res.data) {
            self.deleteDialog = false;
            self.fetchPromoCodeList();
            self.showAlert("success", "Promo Code Deleted Successfully!");
          }
        })
        .catch(e => {
          self.showAlert("error", e);
        });
    },
    async doEditPromo() {
      const self = this;
      try {
        const params = {
          id: self.editPromo.id,
          name: self.editPromo.name,
          promoCode: self.editPromo.promoCode,
          emailTemplate: self.editPromo.emailTemplate,
          planDuration: self.editPromo.planDuration,
          startDate: self.editPromo.startDate,
          endDate: self.editPromo.endDate,
        };
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/magazineSystem/plan`,
          params,
          self.token
        )
          .then(res => {
            if (res.data) {
              self.editDialog = false;
              self.fetchPromoCodeList();
              self.showAlert("success", "Promo Edited Successfully!");
            }
          })
          .catch(e => {
            self.showAlert("error", e);
          });
      } catch (e) {
        self.showAlert("error", e);
        console.error(e);
      }
    },
    async doAddPromo() {
      const self = this;
      try {
        const params = {
          name: self.addPromo.name,
          promoCode: self.addPromo.promoCode,
          emailTemplate: self.addPromo.emailTemplate,
          planDuration: self.addPromo.planDuration,
          startDate: self.addPromo.startDate,
          endDate: self.addPromo.endDate,
        };
        console.log(params);
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/magazineSystem/plan`,
          params,
          self.token
        )
          .then(res => {
            if (res.data) {
              self.dialog = false;
              self.fetchPromoCodeList();
              self.showAlert("success", "New Promo Added!");
            }
          })
          .catch(e => {
            self.showAlert("error", e);
          });
      } catch (e) {
        console.error(e);
      }
    },
    fetchPromoCodeList() {
      // ${process.env.VUE_APP_SERVER_URL}/
      getAxios(`${process.env.VUE_APP_SERVER_URL}/magazineSystem/plans`)
        .then(res => {
          if (res.data) {
            this.PromoCodeList = res.data.data.content;
            // console.log(this.PromoCodeList);
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
    fetchCredentials() {
      var self = this;
      const token = localStorage.getItem("jwt");
      if (token) {
        self.token = token;
      } else {
        self.showAlert("error", "unauthorized..");
      }
    },
  },
};
</script>
